<template>
  <div id="users" class="h-100" :key="key">
    <b-card header="Filtry" header-bg-variant="transparent" no-body>
      <b-card-body class="d-flex justify-content-around transition-filter">
        <div style="min-height: 33px"></div>
        <!-- email FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          taggable
          ref="filters_email"
          name="email"
          @tag="(val) => (filters.email = val)"
          :tagPlaceholder="$t('ALERT.PRESS_ENTER_SEARCH')"
          v-model="filters.email"
          noOptions="Brak wyników"
          :placeholder="$t('TABLE.EMAIL')"
          :options="mapArray(filteredUsers, 'email')"
          :showLabels="false"
        >
          <template slot="noOptions">Brak wyników</template>
        </multi-select>
        <!-- firstName FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          taggable
          ref="filters_firstName"
          name="firstName"
          @tag="(val) => (filters.firstName = val)"
          :tagPlaceholder="$t('ALERT.PRESS_ENTER_SEARCH')"
          v-model="filters.firstName"
          noOptions="Brak wyników"
          :placeholder="$t('TABLE.FIRST_NAME')"
          :options="mapArray(filteredUsers, 'firstName')"
          :showLabels="false"
        >
          <template slot="noOptions">Brak wyników</template>
        </multi-select>
        <!-- lastName FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          taggable
          ref="filters_lastName"
          name="firstName"
          @tag="(val) => (filters.lastName = val)"
          :tagPlaceholder="$t('ALERT.PRESS_ENTER_SEARCH')"
          v-model="filters.lastName"
          noOptions="Brak wyników"
          :placeholder="$t('TABLE.LAST_NAME')"
          :options="mapArray(filteredUsers, 'lastName')"
          :showLabels="false"
        >
          <template slot="noOptions">Brak wyników</template>
        </multi-select>
        <!-- CONFIRMED FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          v-model="filters.confirmed"
          ref="filters_confirmed"
          :searchable="false"
          :placeholder="$t('TABLE.CONFIRMED')"
          name="confirmed"
          :options="['true', 'false']"
          :showLabels="false"
        >
          <template slot="option" slot-scope="props">
            <span>{{ $t(`TABLE.CONFIRMED_${props.option.toUpperCase()}`) }}</span>
          </template>
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t(`TABLE.CONFIRMED_${props.option.toUpperCase()}`) }}</span>
          </template>
        </multi-select>
        <!-- ENABLED FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          v-model="filters.enabled"
          ref="filters_confirmed"
          :searchable="false"
          :placeholder="$t('TABLE.ENABLED')"
          name="enabled"
          :options="['true', 'false']"
          :showLabels="false"
        >
          <template slot="option" slot-scope="props">
            <span>{{ $t(`TABLE.ENABLED_${props.option.toUpperCase()}`) }}</span>
          </template>
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t(`TABLE.ENABLED_${props.option.toUpperCase()}`) }}</span>
          </template>
        </multi-select>
      </b-card-body>
    </b-card>

    <b-card class="mt-4" no-body>
      <b-card-header
        header-bg-variant="transparent"
        class="d-flex align-items-center justify-content-between"
      >
        <span>Użytkownicy</span>
        <div>
          <b-button
            v-if="!isObjectEmpty(filters)"
            class="mx-3"
            variant="light"
            @click="filters = {}"
            >{{ $t("TABLE.CLEAR") }}</b-button
          >
          <b-button
            class="text-white"
            variant="primary"
            @click="$router.push({ name: 'newUser' })"
            >{{ $t("USER.ADD") }}</b-button
          >
        </div>
      </b-card-header>
      <b-card-body>
        <!-- ACTUAL FILTER BEGIN -->
        <div class="d-flex my-2 align-items-center" v-if="filters && !isObjectEmpty(filters)">
          <span>Aktualne filtry:</span>
          <div class="d-flex" v-for="(filter, index) in filters" :key="filter + index">
            <div class="div" v-if="filter && index !== 'duration'">
              <b-badge
                variant="info"
                class="mx-2 d-flex align-items-center cursor-pointer p-2 font-weight-normal"
                v-b-hover="(isHovered) => hoverFilter(isHovered, index)"
                @click="deleteActiveFilter(index)"
              >
                {{
                  "true false".includes(filter)
                    ? $t(`TABLE.${filter}`)
                    : typeof filter === "object"
                    ? filter.join(" do ")
                    : filter
                }}
                <v-icon class="ml-2 text-white" small>fas fa-times</v-icon>
              </b-badge>
            </div>
            <div v-else v-for="item in filter" :key="item">
              <b-badge
                :key="item"
                variant="info"
                class="mx-2 d-flex align-items-center cursor-pointer p-2 font-weight-normal"
                v-b-hover="(isHovered) => hoverFilter(isHovered, index)"
                @click="deleteActiveFilter(index, item)"
              >
                {{ $t(`TABLE.${item.toUpperCase()}`) }}
                <v-icon class="ml-2 text-white" small>fas fa-times</v-icon>
              </b-badge>
            </div>
          </div>
        </div>

        <!-- ACTUAL FILTER END-->
        <vue-good-table
          :columns="columns"
          :rows="filteredUsers"
          :isLoading="isLoading"
          :pagination-options="{
            enabled: true,
            perPage: currentPerPage,
          }"
        >
          <template slot="table-row" slot-scope="{ column, row }">
            <!--  CONFIRMED ROW -->
            <div class="d-flex justify-content-center" v-if="column.field === 'confirmed'">
              <b-badge class="text-white" :variant="row.confirmed ? 'success' : 'danger'">
                {{ $t(`TABLE.CONFIRMED_${row.confirmed.toString().toLocaleUpperCase()}`) }}
              </b-badge>
            </div>
            <!--  ENABLED ROW -->
            <div class="d-flex justify-content-center" v-else-if="column.field === 'enabled'">
              <b-badge class="text-white" :variant="row.enabled ? 'success' : 'danger'">
                {{ $t(`TABLE.ENABLED_${row.enabled.toString().toLocaleUpperCase()}`) }}
              </b-badge>
            </div>
            <!--  ROLES ROW -->
            <div class="d" v-else-if="column.field === 'roles'">
              {{ row.roles.length >= 1 ? row.roles.join(",") : "Brak" }}
            </div>

            <!--  ACTON ROW -->
            <div class="d-flex" v-else-if="column.field === 'action'">
              <b-button
                size="sm"
                class="mx-1 text-white py-0 px-2 m-0"
                variant="primary"
                @click="$router.push({ name: 'UserID', params: { id: row.id, user: row } })"
                >{{ $t("TABLE.SHOW") }}</b-button
              >
              <b-button
                size="sm"
                class="mx-1 text-white py-0 px-2 m-0"
                @click="$router.push({ name: 'editUser', params: { id: row.id, user: row } })"
              >
                {{ $t("TABLE.EDIT") }}</b-button
              >
              <b-button
                size="sm"
                class="mx-1 text-white py-0 px-2 m-0"
                variant="danger"
                @click="deleteUser(row.id)"
                >{{ $t("TABLE.DELETE") }}</b-button
              >
            </div>
          </template>

          <!--  Pagination emptystate -->
          <template slot="emptystate">
            <h2 class="text-black-50 text-center font-weight-bold">{{ $t("NO_RESULT") }}</h2>
          </template>

          <!--  Pagination dataTable -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex mt-4 justify-content-between" style="height: 38px">
              <multi-select
                style="width: 120px"
                :showLabels="false"
                :options="paginationOptions"
                placeholder="Wybierz"
                v-model="currentPerPage"
                @input="(val) => props.perPageChanged({ currentPerPage: val })"
              />
              <b-pagination
                @change="(currentPage) => props.pageChanged({ currentPage: currentPage })"
                :total-rows="props.total"
                :per-page="currentPerPage"
                :value="1"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_USER, GET_USERS } from "@/core/store/Users/users.module";
import { columnUsersList } from "@/views/Pages/Users/UsersList.filter";

export default {
  name: "UsersList",
  components: {},
  data() {
    return {
      isLoading: true,
      filters: { email: null, firstName: null, lastName: null },
      currentPerPage: 10,
      paginationOptions: [10, 15, 20, 40],
      classHoverFilter: "shadow",
      columns: columnUsersList(this.$i18n.locale),
      key: 0,
    };
  },
  methods: {
    hoverFilter(isHovered, nameFilter, deleteFilter = false) {
      const refInputFilter = this.$refs[`filters_${nameFilter}`];
      if (refInputFilter) refInputFilter.$el.classList.toggle(this.classHoverFilter);
      if (refInputFilter && deleteFilter) {
        refInputFilter.$el.classList.remove(this.classHoverFilter);
      }
    },
    deleteActiveFilter(name, elArray) {
      const filter = this.filters[name];
      const indexEl = elArray && this.filters[name].findIndex((i) => i === elArray);

      if (elArray && filter.length !== 1) filter.splice(indexEl, 1);
      else this.$set(this.filters, name, null);

      this.hoverFilter(null, name, true);
    },
    deleteUser(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("SURE"), {
          okTitle: "Tak",
          footerClass: "text-white",
          cancelTitle: "Anuluj",
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch(DELETE_USER, id)
              .then(() => {
                this.usersDispatch();
                this.toastSuccess(this.$t("SUCCESS"), this.$t("SUCCESS"));
              })
              .catch(() => {
                this.toastError("Warning", "You can't delete this user ");
              });
          }
        });
    },
    usersDispatch() {
      this.isLoading = true;
      this.$store.dispatch(GET_USERS).then(() => {
        this.isLoading = false;
      });
    },
  },
  computed: {
    filteredUsers() {
      // prettier-ignore
      // eslint-disable-next-line max-len
      return this.getUsers && this.getUsers.list.filter((i) => Object.keys(this.filters)
        // eslint-disable-next-line max-len,no-nested-ternary
        .every((filterName) => {
          const filter = this.filters[filterName];
          const value = typeof i[filterName] === "string" ? i[filterName].toLowerCase() : i[filterName];
          // eslint-disable-next-line no-nested-ternary,max-len
          return filter ? i[filterName] ? value.toString().includes(filter.toString().toLowerCase()) : true : true;
        }));
    },
    ...mapGetters(["getUsers"]),
  },
  mounted() {
    this.usersDispatch();
  },
};
</script>

<style lang="scss">
.transition-filter {
  .multiselect,
  .mx-datepicker {
    transition: 0.4s ease-in-out;
  }
}

.reset-input {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  padding: 4px;
  background: white;
  z-index: 99999;
  cursor: pointer;
}
</style>
