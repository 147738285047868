/* eslint-disable no-alert */

export const columnUsersList = (lang) => [
  {
    label: "Id",
    field: "id",
  },
  {
    label: lang === "pl" ? "E-mail" : "E-mail",
    field: "email",
  },
  {
    label: lang === "pl" ? "Imię" : "First name",
    field: "firstName",
    tdClass: "td-15",
  },
  {
    label: lang === "pl" ? "Nazwisko" : "Last name",
    field: "lastName",
  },
  {
    label: lang === "pl" ? "Zatwierdzone" : "Confirmed",
    field: "confirmed",
  },
  {
    label: lang === "pl" ? "Aktywne" : "Enabled",
    field: "enabled",
  },
  {
    label: lang === "pl" ? "Role" : "Role",
    field: "roles",
  },
  {
    label: lang === "pl" ? "Akcje" : "Action",
    field: "action",
    sortable: false,
    width: "200px",
  },
];
